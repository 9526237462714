<template>
  <div class="all-topics">
    <BackBar :pageTit="headTit" />
    <vue-scroll class="main-page" :ops="ops"
      @refresh-start="handleRefresh"
      @load-start="handleLoad"
       ref="vs"
    >
    <div class="topics">
      <div class="topic" v-for="_ in users" :key="'user' + _.id">
        <div class="follow_fl"  @click="go(_.id)">
          <div class="avatar" :style="{ 'background-image': 'url(' + _.headimgurl + ')' }"></div>
          <div class="follow_txt">
              <div>{{ _.title }}</div>
              <p>{{_.goalTitle.thisLv.title}}</p>
          </div>
        </div>
        <FollowBtn :user_id="_.id" :is_follow="true" />
      </div>
    </div>
    </vue-scroll>
  </div>
</template>

<script>
// @ is an alias to /src
import FollowBtn from '@/components/FollowBtn.vue';
import BackBar from "@/components/BackBar.vue";
import Api from "@/api";
import Vue from "vue";

export default {
  name: "Home",
  data() {
    const ops = {
      vuescroll: {
        mode: 'slide',
        pullRefresh: {
          enable: true
        },
        pushLoad: {
          enable: true,
        }
      }
    };
   
      ops.vuescroll.pullRefresh.tips = {
        deactive: '下拉刷新',
        active: '释放刷新',
        start: '刷新中...',
        beforeDeactive: '刷新成功!'
      };
      ops.vuescroll.pushLoad.tips = {
        deactive: '上拉加载',
        active: '释放加载',
        start: '加载中...',
        beforeDeactive: '加载成功!'
      };
    return {
      page:1,
       ops:ops,
       isloading:false,
      users: [],
      headTit: "我的关注",
    };
  },
  components: {
    BackBar,
    FollowBtn
  },
  methods: {
    go(id) {
      this.$router.push("/user/" + id);
    },
    handleRefresh(vm, refreshDom, done){
      if(this.isloading){return;}
      console.log('refresh')
      this.page=1;
      this.users=[];
      this.refreshPost(done)
    },
    handleLoad(vm, refreshDom, done){
      if(this.isloading){return;}
      console.log('handleLoad')
      this.refreshPost(done)
    },
    refreshPost(cb){
      var that=this
      this.isloading=true
      
      Api.go('my_follow',{page:this.page++},(res)=>{
        var _=that.users
        if(res.length>0){
          for(var i in res){
            _.push(res[i])
          }
        }else{
          Vue.prototype.$toast('暂无更多')
        }
        if(cb){cb();}
        that.isloading=false;
        setTimeout(() => {
          that.$refs.vs.refresh();
        }, 100)
      }) 
    }
  },
  mounted() {
    this.refreshPost();
  },
};
</script>

<style lang="less" scoped>
.topics{
    padding: .32rem;
    background: #fff;
    .topic{
        margin-bottom: .48rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .follow_fl{
            display: flex;
            align-items: center;
            .avatar{
                width: .96rem;
                height: .96rem;
                border-radius: 50%;
                margin-right: .24rem;
                background-size: cover;
                background-position: center;
            }
            .follow_txt{
                div{
                    font-size: .28rem;
                    color: #333;
                    margin-bottom: .08rem;
                }
                p{
                    font-size: .22rem;
                    color: #F65102;
                    padding: 0 .14rem;
                    border: 0.02rem solid #F65102;
                    border-radius: .16rem;
                }
            }
        }
       
        &:last-child{
            margin-bottom: 0;
        }
    }
}
/* .all-topics .topic{height: 1rem;line-height: 1rem;display: block;padding:0 0.2rem;border-bottom: 1px solid #ccc;}
.all-topics .topic i{float: right;width: 0.32rem;display: inline-block;background:url(~@/assets/img/right.png) no-repeat center center /100% auto;height: 100%;}
.all-topics .topic span{float: left;}
.all-topics .topic span.avatar{width: 0.8rem;height: 0.8rem;background-size: cover;border-radius: 0.8rem;margin:0.1rem} */
</style>